@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@include motion-ui-transitions;
@include motion-ui-animations;

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@font-face {
    font-family: 'HelveticaNeueMed';
    src: url('../fonts/HelveticaNeueMed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Milkshake';
    src: url('../fonts/Milkshake.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
	font-family: HelveticaNeueMed;
}

.sign {
	font-family: 	Milkshake;
	font-size:	24px;
	padding-left:	30px;
}

.menu 			{ background-color: 	black; 			}
.menu li 		{ text-transform: 	uppercase; 		}
.menu li a 		{ color: 		white !important; 	
			  background-color:	black !important;	}
.menu .is-active > a	{ color:		red !important; 	}

.footer			{ position:		fixed;
			  bottom:		0;
			  z-index:		100000;
			  text-align:		right;
			  padding-right:	40px;
			  padding-top:		10px;
			  padding-bottom:	10px;
			  background-color:     none;
			  color:                black;
			  width:		100%;			}

.footerfloat		{ text-align:		right;
			  padding-right:	40px;
			  padding-top:		10px;
			  padding-bottom:	10px;
			  background-color:     none;
			  color:                black;
			  width:		100%;			}



#frontright		{ background-color:	#eee !important;
			  text-align:		center;
			  line-height:		96vh;
			  min-height:		96vh;			
                          padding-left:         40px;
                          padding-right:        40px;
									}
#frontrightp		{ background-color:	#eee !important;
			  text-align:		center;
			  line-height:		96vh;
			  min-height:		96vh;			
			  padding-left:		80px;
			  padding-right:	80px;
									}
.textcenter		{ text-align:		center;			
			  padding-top:		0px;
			  padding-bottom:	0px;
			  margin-top:		0px;
			  margin-bottom:	0px;			}
#frontright img		{ max-height:		75vh;
			  vertical-align:	middle;			}
#frontleft		{ vertical-align:	middle;
	 		  line-height:          96vh;
			  min-height:           96vh;			}


.fade-in {
  // A long, long fade
//  @include mui-fade(in, $duration: 3s);
}

.sil {
//  @include mui-slide(in,right,8%,true,3s,null,null);
}

.sir {
//  @include mui-slide(in,left,4%,true,3s,null,null);
}

.siu {
//  @include mui-slide(in,up,4%,true,1s,null,2s);
}

.sid {
//  @include mui-slide(in,down,25%,true,1s,null,2s);
}

.tblc {
  border:	1px solid #ccc;
  border-right: none;
  color:	white;
  background-color:	#676767;
}

.tblr {
  border:	1px solid #ccc;
  color:	white;
  background-color:	#676767;
}

.tblcr {
  border:	1px solid #ccc;

  border-top:	none;
  padding-right: 10px;
}

.tblci {
  border:	1px solid #ccc;
  border-right: none;
  border-top:	none;
  padding-right: 10px;
}

.tblcit {
  padding-right: 10px;
}

.tblri {
  border:	1px solid #ccc;
  border-top:	none;
  //padding-right: 10px;
}

.tblt {
  border-left:	1px solid #ccc;
}

.npbutton {
  border-radius: 25px;
  border:1px solid #cecece;
  color:white;
  background-color:#24d164;
}

.homeleft {
  @include breakpoint(medium) {
    line-height:	33vh;
  }
  @include breakpoint(small) {
    line-height:	31vh;
  }
}

.hometop {
  @include breakpoint(medium) {
    line-height:	33vh;
  }
  @include breakpoint(small) {
    line-height:	5vh;
  }

}

.picprice {
  @include breakpoint(large) {
    padding-left:		11px;
  }
}

.buybutton {
  border-radius:	25px;
  border:		1px solid #cecece;
  color:		white;
  background-color:	#24d164;
  outline:		none;
}

.valigndiv:before { content:''; display:inline-block; height:100%; vertical-align:middle; }

.valigndivb:before { content:''; display:inline-block; height:100%; vertical-align:bottom; @media screen and (max-width:450px) and (orientation:portrait) { display:none; }}

.spc {
  @media screen and (max-width:320px) and (orientation:portrait) {
    padding-top:15px !important;
  }
  @media screen and (max-width:568px) and (orientation:landscape) {
    padding-top:15px !important;
  }
}

.spd {
  @media screen and (max-width:1024px) and (orientation:portrait) {
    padding-top:25px !important;
  }
}

.stdspn3 {
  font-family:'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size:36px;
}

.stdspn4 {
  font-family:'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size:28px;

  @media screen and (min-width:1024px) and (max-width:1024px) and (orientation:portrait) { font-size: 40px; }

}

.stdspn5 {
  font-family:'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size:20px;

  @media screen and (min-width:1024px) and (max-width:1024px) and (orientation:portrait) { font-size: 25px; }
}

.stdspn6 {
  font-family:'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size:18px;

  @media screen and (min-width:1024px) and (max-width:1024px) and (orientation:portrait) { font-size: 25px; }
}

.works {
  font-family:'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size:25px;

  @media screen and (max-width:450px) and (orientation:portrait) {
    display:none;
  }

  //iPad Fix
  @media screen and (min-width:768px) and (max-width:768px) and (orientation:portrait) {
    display:none;
  }
}

.workd {
  font-family:'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size:14px;

  @media screen and (min-width:451px) {
    display:none;
  }

  @media screen and (min-width:768px) and (max-width:768px) and (orientation:portrait) { display:inline-block; font-size:18px; }
}

.imagefix {
  @media screen and (min-width:1900px) and (orientation:landscape) { max-width:87% }
}

.ip5 {
  @media screen and (min-width:568px)and (max-width:568px)and (orientation:landscape)  { display:none !important; }
}
.ip5i {
  display:none !important;
  @media screen and (min-width:568px)and (max-width:568px)and (orientation:landscape)  { display:inline-block !important; }
}



.smallscreen {
  //motoG4
  @media screen and (min-width:640px)and (max-width:640px)and (orientation:landscape) { display:none !important; }
  @media screen and (min-width:360px)and (max-width:360px)and (orientation:portrait)  { display:none !important; }

  //Pixel2
  @media screen and (min-width:731px)and (max-width:731px)and (orientation:landscape) { display:none !important; }

  //Pixel2 XL
  @media screen and (min-width:823px)and (max-width:823px)and (orientation:landscape) { display:none !important; }

  //iphone 5/SE
  @media screen and (min-width:568px)and (max-width:568px)and (orientation:landscape) { display:none !important; }

  //iphone 6/7/8
  @media screen and (min-width:667px)and (max-width:667px)and (orientation:landscape) { display:none !important; }

  //iphone 6/7/8 Plus
  @media screen and (min-width:736px)and (max-width:736px)and (orientation:landscape) { display:none !important; }

  //iphone X
  @media screen and (min-width:812px)and (max-width:812px)and (orientation:landscape) { display:none !important; }

  //Surface Duo
  @media screen and (min-width:600px)and (max-width:600px)and (orientation:landscape) { display:none !important; }

  //Galaxy Fold
  @media screen and (min-width:653px)and (max-width:653px)and (orientation:landscape) { display:none !important; }
}

.ipadfix {
  @media screen and (min-width:768px)and (max-width:768px)and (orientation:portrait) { padding-top: 40px; }
}

.iphonex {
 @media screen and (min-width:812px) and (max-width:812px) and (orientation:landscape) {
   top: -50px;
   position: relative;
 }
 @media screen and (min-width:667px) and (max-width:667px) and (orientation:landscape) {
   top: -50px;
   position: relative;
 }
 @media screen and (min-width:736px) and (max-width:736px) and (orientation:landscape) {
   top: -50px;
   position: relative;
 }
 @media screen and (min-width:823px) and (max-width:823px) and (orientation:landscape) {
   top: -50px;
   position: relative;
 }
 @media screen and (min-width:731px) and (max-width:731px) and (orientation:landscape) {
   top: -50px;
   position: relative;
 }
 @media screen and (min-width:640px) and (max-width:640px) and (orientation:landscape) {
   top: -50px;
   position: relative;
 }
 @media screen and (min-width:653px) and (max-width:653px) and (orientation:landscape) {
   top: -50px;
   position: relative;
 }
 @media screen and (min-width:654px) and (orientation:landscape) {
   top: -50px;
   position: relative;
 }
 @media screen and (min-width:414px) and (max-width:414px) and (orientation:portrait) {
   top: -30px;
   position: relative;
 }
 @media screen and (min-width:375px) and (max-width:375px) and (orientation:portrait) {
   top: -30px;
   position: relative;
 }
 @media screen and (min-width:320px) and (max-width:320px) and (orientation:portrait) {
   top: -30px;
   position: relative;
 }
 @media screen and (min-width:411px) and (max-width:411px) and (orientation:portrait) {
   top: -30px;
   position: relative;
 }
 @media screen and (min-width:360px) and (max-width:360px) and (orientation:portrait) {
   top: -30px;
   position: relative;
 }
 @media screen and (min-width:280px) and (max-width:280px) and (orientation:portrait) {
   top: -30px;
   position: relative;
 }
}